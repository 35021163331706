import type { ComponentPropsWithoutRef } from "react";

export function StaderLogo(props: ComponentPropsWithoutRef<"svg">) {
  return (
    // biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.4001 0.76737C18.8495 0.819468 19.1344 1.27529 18.9842 1.70209L16.7864 7.94736C16.5902 8.50494 15.8362 8.58828 15.523 8.08698L15.0539 7.33632L6.57198 12.6364L5.69964 11.2404C4.30635 9.01068 4.98442 6.07362 7.21416 4.68033L11.6588 1.903L11.1441 1.07934C10.8309 0.578044 11.2363 -0.063069 11.8235 0.0049979L18.4001 0.76737ZM9.5076 15.248C6.44328 17.0172 5.39333 20.9356 7.16256 23.9999L15.793 19.0172C18.07 17.7025 18.8501 14.791 17.5355 12.514L16.7124 11.0883L9.5076 15.248Z"
        fill="#07C166"
      />
    </svg>
  );
}
