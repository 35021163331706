import { ArchiveBoxIcon } from "@enzymefinance/icons/outline";
import { ArrowsRightLeftIcon, ChevronDoubleUpIcon, CogIcon } from "@enzymefinance/icons/solid";

import { Network } from "@enzymefinance/environment";
import { useNetwork } from "../providers/NetworkProvider";
import { Sidebar } from "./Sidebar";

interface TraderSidebarProps {
  id: string;
}

export function TraderSidebar({ id }: TraderSidebarProps) {
  const { network } = useNetwork();

  return (
    <>
      <Sidebar.Item icon={ArrowsRightLeftIcon} to={`/vault/${id}/trade`}>
        Trade
      </Sidebar.Item>

      {network !== Network.ARBITRUM ? (
        <Sidebar.Item icon={ArchiveBoxIcon} to={`/vault/${id}/basket-trade`}>
          Basket Trade
        </Sidebar.Item>
      ) : null}

      <Sidebar.Item icon={ChevronDoubleUpIcon} to={`/vault/${id}/protocols`}>
        DeFi Protocols
      </Sidebar.Item>

      <Sidebar.Item icon={CogIcon} to={`/vault/${id}/settings`}>
        Settings
      </Sidebar.Item>
    </>
  );
}
