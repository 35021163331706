import { XMarkIcon } from "@enzymefinance/icons/solid";
import { ArrowTopRightOnSquareIcon } from "@enzymefinance/icons/src/solid";
import { Container, Icon } from "@enzymefinance/ui";
import { StaderLogo } from "components/images/StaderLogo";
import { InlineLink } from "components/routing/Link";
import { useState } from "react";

function useStaderBanner() {
  const [isStaderBannerOpened, setIsStaderBannerOpened] = useState(true);

  function closeStaderBanner() {
    setIsStaderBannerOpened(false);
  }

  return {
    isStaderBannerOpened,
    closeStaderBanner,
  };
}

export function StaderBanner() {
  const { isStaderBannerOpened, closeStaderBanner } = useStaderBanner();

  if (!isStaderBannerOpened) {
    return null;
  }

  return (
    <div className="dark:bg-white bg-black">
      <Container padded={true} className="font-medium py-2 gap-y-2 flex items-center justify-between flex-wrap">
        <div className="flex space-x-4">
          <StaderLogo width={24} height={24} />
          <span className="dark:text-black text-white">
            ETHx Hyperloop by Stader is now live! Increase your staking APY% with our automated leverage vault.
          </span>
        </div>
        <div className="flex flex-1 space-x-4 items-center justify-end">
          <InlineLink
            className="w-32"
            target="_self"
            to="https://app.enzyme.finance/vault/0x040a9562201b2a3456a7c9052d88ce37e994ee9d"
          >
            <span className="dark:text-black text-white flex hover:opacity-60">
              <span>Deposit here</span>&nbsp;
              <ArrowTopRightOnSquareIcon width={20} />
            </span>
          </InlineLink>
          <InlineLink
            className="w-32"
            to="https://medium.com/enzymefinance/introducing-ethx-hyperloop-a-new-product-by-stader-labs-powered-by-enzyme-5ba18be0885e"
          >
            <span className="dark:text-black text-white flex hover:opacity-60">
              <span>Read More</span>&nbsp;
              <ArrowTopRightOnSquareIcon width={20} />
            </span>
          </InlineLink>
          <button onClick={closeStaderBanner} type="button" className="p-2 dark:text-black text-white hover:opacity-40">
            <Icon size={6} icon={XMarkIcon} />
          </button>
        </div>
      </Container>
    </div>
  );
}
